import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

// * import redux functions
import { setUserProfile } from "../../features/authSlice";

// * import utilities
import { colors } from "../../assets/utils/colors";
import logo from "../../assets/media/images/logo.png";

// * import required components
import AppName from "../../components/AppName";
import CustomTextField from "../../components/forms/CustomTextField";

// * import API services
import { useLoginMutation } from "../../api/auth/authAPI";
import CustomSubmitButton from "../../components/forms/CustomSubmitButton";
import { LoginTwoTone } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { errorNotify, successNotify } from "../../helpers/notify";
import useAuth from "../../hooks/useAuth";
import {
  getRTKErrorMessage,
  getRTKResponseData,
} from "../../helpers/RTKHelpers";
import HotToastfy from "../../components/HotToastfy";

const Login = () => {
  // ################# CONST #######################
  const dispatch = useDispatch();
  const auth = useAuth();

  // ################# RTK #######################
  const [login, { isLoading, isSuccess, isError, error, data: loginData }] =
    useLoginMutation();

  // ################# FUNC #######################
  const handleLogin = async (payload) => {
    let res = await login(payload);
    if (!isEmpty(res.data)) {
      dispatch(setUserProfile(res.data));
      auth.saveAuthenticatedUser(res.data);
      setTimeout(() => {
        successNotify("User login successfully");
      }, 1000);
    } else if (res.error) {
      return errorNotify(
        res.error.data.message
          ? res.error.data.message
          : "Temporary server error. Please try again letter."
      );
    } else {
      let message = res.error.data.message;
      return errorNotify(message);
    }
  };

  return (
    <>
      <HotToastfy />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: colors.primary,
        }}
      >
        <Grid container justifyContent={"center"}>
          <Grid
            item
            sm={8}
            className="neo-up"
            sx={{
              borderRadius: { md: 10, xs: 5 },
              overflow: "hidden",
            }}
          >
            <Grid container rowGap={5} columnSpacing={2}>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  borderRadius: 8,
                  overflow: "hidden",
                  borderRight: {
                    md: `20px solid ${colors.bgColor6}`,
                    xs: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    height: { md: 500, xs: "100%" },
                    bgcolor: colors.primary,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Avatar
                      src={logo}
                      sx={{ height: 265, width: 265 }}
                      variant="square"
                    />
                    <Box>
                      <AppName textSize={24} desktopOpen />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} sx={{ pb: { md: 0, xs: 3 } }}>
                <Box
                  sx={{
                    height: { md: 500, xs: "100%" },
                    bgcolor: colors.primary,
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      px: 2,
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        sx={{
                          fontSize: 28,
                          textAlign: "center",
                          fontWeight: "bold",
                          color: colors.white,
                          mb: 2,
                        }}
                      >
                        Happy to see you back
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textAlign: "center",
                          fontWeight: "bold",
                          color: colors.white,
                        }}
                      >
                        Caica Pharmacy admin login
                      </Typography>
                    </Box>
                    <Box>
                      <Formik
                        initialValues={{
                          phone_number: "",
                          password: "",
                          //   phone_number: "0718793810",
                          //   password: "buibui123",
                        }}
                        onSubmit={(values) => {
                          handleLogin(values);
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <CustomTextField
                              label={"Phone Number"}
                              name={"phone_number"}
                              type="text"
                            />
                            <CustomTextField
                              label={"Password"}
                              name={"password"}
                              type="password"
                            />
                            <Box
                              sx={{
                                mt: 4,
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <CustomSubmitButton
                                loading={isLoading}
                                startIcon={<LoginTwoTone />}
                                btnColor={"secondary"}
                                onClick={formik.handleSubmit}
                                loadingColor={"secondary"}
                              >
                                Login
                              </CustomSubmitButton>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
