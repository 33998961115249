import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USER_LOGIN, USER_LOGOUT } from '../endPoints';
import { baseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${USER_LOGIN}`,
                method: 'POST',
                body: payload,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${USER_LOGOUT}`,
                method: `POST`,
                headers: headers,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authAPI;
