// base url
export const clientUrl = 'https://secure.caica.co.tz/api';

// authentication api routes
export const USER_LOGIN = '/managers/login';
export const USER_LOGOUT = '/logout';
export const GET_USER_PROFILE = '/user';

// branches api routes
export const GET_BRANCHES = '/branches';
export const GET_SINGLE_BRANCH = '/branches/';
export const GET_BRANCH_PRODUCTS = '/managers/products/branch/';
export const CREATE_BRANCH = '/branches';
export const UPDATE_BRANCH = '/branches';
export const ACTIVATE_BRANCH = '/branches/activate';
export const DEACTIVATE_BRANCH = '/branches/deactivate';
export const DELETE_BRANCH = '/branches';

// products api routes
export const GET_PRODUCTS = '/managers/products';
export const ACTIVATE_PRODUCT = '/managers/products/activate/';
export const PRODUCT_SIZES = '/managers/products/size/';
export const PRODUCT_IMAGES = '/managers/products/images/';
export const SET_PRODUCT_ON_SELL = '/managers/products/set-on-sell/';
export const GET_PRODUCT_BY_PROMOTION = '/managers/products/promotions/';
export const UPDATE_PRODUCT_STOCK = '/managers/products/stocks';
export const ADD_PRODUCT_PROMOTION = '/managers/products/promotions/add';
export const REMOVE_PRODUCT_PROMOTION = '/managers/products/promotions/remove';
export const DELETE_PRODUCT = '/managers/products';

// promotions api routes
export const GET_PROMOTIONS = '/promotions';
export const GET_SINGLE_PROMOTIONS = '/promotions/';
export const CREATE_PROMOTION = '/promotions';
export const UPDATE_PROMOTION = '/promotions';
export const ACTIVATE_PROMOTION = '/promotions/activate';
export const DEACTIVATE_PROMOTION = '/promotions/deactivate';
export const DELETE_PROMOTION = '/promotions';

// orders api routes
export const GET_ORDERS = '/orders';
export const GET_SINGLE_ORDER = '/orders/';
export const DELIVER_ORDER = '/orders/delivery/';
export const SHIP_ORDER = '/orders/ship';

// brands api routes
export const GET_BRANDS = '/brands';
export const GET_SINGLE_BRAND = '/brands/';
export const GET_BRAND_PRODUCTS = '/managers/products/brand/';
export const CREATE_BRAND = '/brands';
export const UPDATE_BRAND = '/brands';
export const ACTIVATE_BRAND = '/brands/activate';
export const DEACTIVATE_BRAND = '/brands/deactivate';
export const DELETE_BRAND = '/brands';

// categories api routes
// => MAIN
export const GET_CATEGORIES = '/categories';
export const CREATE_CATEGORY = '/categories';
export const UPDATE_CATEGORY = '/categories';
export const ACTIVATE_CATEGORY = '/categories/activate';
export const DEACTIVATE_CATEGORY = '/categories/deactivate';
export const DELETE_CATEGORY = '/categories';
// => SUB
export const GET_SUB_CATEGORIES = '/sub-categories';
export const CREATE_SUB_CATEGORY = '/sub-categories';
export const UPDATE_SUB_CATEGORY = '/sub-categories';
export const ACTIVATE_SUB_CATEGORY = '/sub-categories/activate';
export const DEACTIVATE_SUB_CATEGORY = '/sub-categories/deactivate';
export const DELETE_SUB_CATEGORY = '/sub-categories';

// customers api routes
export const GET_ALL_CUSTOMERS = '/customers';
export const GET_SINGLE_CUSTOMER = '/customers/';
export const GET_CUSTOMER_ORDERS = '/orders/customers/';

// managers api routes
export const GET_ALL_MANAGERS = '/managers';
