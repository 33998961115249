import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GET_SINGLE_ORDER, DELIVER_ORDER, SHIP_ORDER } from '../endPoints';
import { baseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';

export const orderAPI = createApi({
    reducerPath: 'orderAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getOneOrder: builder.query({
            query: (order_id) => `${GET_SINGLE_ORDER}${order_id}`,
        }),
        deliverOrder: builder.mutation({
            query: (order_id) => ({
                url: `${DELIVER_ORDER}${order_id}`,
                method: 'POST',
                body: [],
            }),
        }),
        shipOrder: builder.mutation({
            query: (payload) => ({
                url: `${SHIP_ORDER}/${payload.order_id}`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetOneOrderQuery,
    useDeliverOrderMutation,
    useShipOrderMutation,
} = orderAPI;
