import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_PRODUCTS,
    ACTIVATE_PRODUCT,
    PRODUCT_SIZES,
    PRODUCT_IMAGES,
    SET_PRODUCT_ON_SELL,
    GET_PRODUCT_BY_PROMOTION,
    DELETE_PRODUCT,
    UPDATE_PRODUCT_STOCK,
    REMOVE_PRODUCT_PROMOTION,
    ADD_PRODUCT_PROMOTION,
} from '../endPoints';
import { baseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';

export const productAPI = createApi({
    reducerPath: 'productAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => `${GET_PRODUCTS}`,
        }),
        getSingleProduct: builder.query({
            query: (id) => `${GET_PRODUCTS}/${id}`,
        }),
        getProductByPromotion: builder.query({
            query: (promotion_id) =>
                `${GET_PRODUCT_BY_PROMOTION}${promotion_id}`,
        }),
        activateProduct: builder.mutation({
            query: (id) => ({
                url: `${ACTIVATE_PRODUCT}${id}`,
                method: 'POST',
                body: [],
            }),
        }),
        deleteProductSize: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_SIZES}${id}`,
                method: 'DELETE',
                body: [],
            }),
        }),
        addProductSize: builder.mutation({
            query: (payload) => ({
                url: `${PRODUCT_SIZES}${payload.product_id}`,
                method: 'POST',
                body: payload,
            }),
        }),
        addProductImage: builder.mutation({
            query: (payload) => ({
                url: `${PRODUCT_IMAGES}${payload.get('product_id')}`,
                method: 'POST',
                body: payload,
            }),
        }),
        deleteProductImage: builder.mutation({
            query: (product_id) => ({
                url: `${PRODUCT_IMAGES}${product_id}`,
                method: 'DELETE',
                body: [],
            }),
        }),
        addProductOnSell: builder.mutation({
            query: (payload) => ({
                url: `${SET_PRODUCT_ON_SELL}${payload.product_id}`,
                method: 'POST',
                body: payload,
            }),
        }),
        addNewProduct: builder.mutation({
            query: (payload) => ({
                url: `${GET_PRODUCTS}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateProduct: builder.mutation({
            query: (payload) => ({
                url: `${GET_PRODUCTS}/${payload.get('product_id')}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateProductStock: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_PRODUCT_STOCK}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        addProductPromotion: builder.mutation({
            query: (payload) => ({
                url: `${ADD_PRODUCT_PROMOTION}/${payload.product_id}/${payload.promotion_id}`,
                method: 'PUT',
            }),
        }),
        removeProductPromotion: builder.mutation({
            query: (payload) => ({
                url: `${REMOVE_PRODUCT_PROMOTION}/${payload.product_id}/${payload.promotion_id}`,
                method: 'PUT',
            }),
        }),
        deleteProduct: builder.mutation({
            query: (payload) => ({
                url: `${DELETE_PRODUCT}/` + payload,
                method: `DELETE`,
            }),
        }),
    }),
});

export const {
    useAddProductOnSellMutation,
    useGetProductsQuery,
    useGetSingleProductQuery,
    useActivateProductMutation,
    useAddProductSizeMutation,
    useDeleteProductSizeMutation,
    useAddProductImageMutation,
    useDeleteProductImageMutation,
    useAddNewProductMutation,
    useUpdateProductMutation,
    useGetProductByPromotionQuery,
    useUpdateProductStockMutation,
    useAddProductPromotionMutation,
    useRemoveProductPromotionMutation,
    useDeleteProductMutation,
} = productAPI;
