// -> format number with commas as thousands separators
export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// -> get order chip color by passing order status
export const getChipColor = (status) => {
  if (status === 'delivered') {
    return 'success';
  }
  if (status === 'placed') {
    return 'secondary';
  }
  if (status === 'shipped') {
    return 'info';
  }
  if (status === 'paid') {
    return 'primary';
  }
  if (status === 'cancelled') {
    return 'error';
  }
  if (status === 'not_paid') {
    return 'warning';
  }
};
