import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    promotions: [],
    promotion: {},
};

export const promotionSlice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {
        setPromotions: (state, action) => {
            state.promotions = action.payload;
        },
        addPromotion: (state, action) => {
            let promo = action.payload;
            state.promotions = [promo, ...state.promotions];
        },
        setPromotion: (state, action) => {
            state.promotion = action.payload;
        },
    },
});

export const {
    setPromotions,
    addPromotion,
    setPromotion,
} = promotionSlice.actions;

export default promotionSlice.reducer;
