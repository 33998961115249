import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_SINGLE_PROMOTIONS,
    ACTIVATE_PROMOTION,
    DEACTIVATE_PROMOTION,
    CREATE_PROMOTION,
    UPDATE_PROMOTION,
    DELETE_PROMOTION,
} from '../endPoints';
import { headers } from '../baseUrl/setHeaders';
import { baseURL } from '../baseUrl/baseURL';

export const promotionAPI = createApi({
    reducerPath: 'promotionAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getSinglePromotion: builder.query({
            query: (id) => `${GET_SINGLE_PROMOTIONS}${id}`,
        }),
        createPromotion: builder.mutation({
            query: (payload) => ({
                url: `${CREATE_PROMOTION}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updatePromotion: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_PROMOTION}/${payload.get('promotion_id')}`,
                method: 'POST',
                body: payload,
            }),
        }),
        activatePromotion: builder.mutation({
            query: (payload) => ({
                url: `${ACTIVATE_PROMOTION}/${payload}`,
                method: 'PUT',
            }),
        }),
        deactivatePromotion: builder.mutation({
            query: (payload) => ({
                url: `${DEACTIVATE_PROMOTION}/${payload}`,
                method: 'PUT',
            }),
        }),
        deletePromotion: builder.mutation({
            query: (payload) => ({
                url: `${DELETE_PROMOTION}/${payload}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetSinglePromotionQuery,
    useCreatePromotionMutation,
    useUpdatePromotionMutation,
    useActivatePromotionMutation,
    useDeactivatePromotionMutation,
    useDeletePromotionMutation,
} = promotionAPI;
