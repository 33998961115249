import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_CATEGORIES,
    GET_SUB_CATEGORIES,
    CREATE_CATEGORY,
    CREATE_SUB_CATEGORY,
    ACTIVATE_CATEGORY,
    DEACTIVATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_SUB_CATEGORY,
    DEACTIVATE_SUB_CATEGORY,
    ACTIVATE_SUB_CATEGORY,
    UPDATE_SUB_CATEGORY,
} from '../endPoints';
import { baseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';

export const categoryAPI = createApi({
    reducerPath: 'categoryAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => Get all categories
        getCategories: builder.query({
            query: () => `${GET_CATEGORIES}`,
        }),
        // => create category
        createCategory: builder.mutation({
            query: (payload) => ({
                url: `${CREATE_CATEGORY}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // => Update category
        updateCategory: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_CATEGORY}/${payload.category_id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        // => Activate category
        activateCategory: builder.mutation({
            query: (payload) => ({
                url: `${ACTIVATE_CATEGORY}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Deactivate category
        deactivateCategory: builder.mutation({
            query: (payload) => ({
                url: `${DEACTIVATE_CATEGORY}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Delete category
        deleteCategory: builder.mutation({
            query: (payload) => ({
                url: `${DELETE_CATEGORY}/${payload}`,
                method: 'DELETE',
            }),
        }),
        // => Get sub-categories
        getAllSubCategories: builder.query({
            query: () => `${GET_SUB_CATEGORIES}`,
        }),
        // => create sub category
        createSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${CREATE_SUB_CATEGORY}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // => Update sub category
        updateSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_SUB_CATEGORY}/${payload.sub_category_id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        // => Activate sub category
        activateSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${ACTIVATE_SUB_CATEGORY}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Deactivate sub category
        deactivateSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${DEACTIVATE_SUB_CATEGORY}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Delete sub category
        deleteSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${DELETE_SUB_CATEGORY}/${payload}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    // Main
    useGetCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useActivateCategoryMutation,
    useDeactivateCategoryMutation,
    useDeleteCategoryMutation,
    // Sub
    useGetAllSubCategoriesQuery,
    useCreateSubCategoryMutation,
    useUpdateSubCategoryMutation,
    useActivateSubCategoryMutation,
    useDeactivateSubCategoryMutation,
    useDeleteSubCategoryMutation,
} = categoryAPI;
