import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// * import reducers
import authReducer from '../features/authSlice';
import brandReducer from '../features/brandSlice';
import branchReducer from '../features/branchSlice';
import categoryReducer from '../features/categorySlice';
import userReducer from '../features/userSlice';
import promotionReducer from '../features/promotionSlice';
import productReducer from '../features/productSlice';
import orderReducer from '../features/orderSlice';

// * import RTK api
import { authAPI } from '../api/auth/authAPI';
import { brandAPI } from '../api/brands/brandAPI';
import { branchAPI } from '../api/branches/branchAPI';
import { categoryAPI } from '../api/categories/categoryAPI';
import { userAPI } from '../api/users/userAPI';
import { dashboardAPI } from '../api/dashboard/dashboardAPI';
import { orderAPI } from '../api/orders/orderAPI';
import { productAPI } from '../api/products/productAPI';
import { promotionAPI } from '../api/promotions/promotionAPI';

export const store = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [brandAPI.reducerPath]: brandAPI.reducer,
    [branchAPI.reducerPath]: branchAPI.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [orderAPI.reducerPath]: orderAPI.reducer,
    [productAPI.reducerPath]: productAPI.reducer,
    [promotionAPI.reducerPath]: promotionAPI.reducer,
    auth: authReducer,
    branches: branchReducer,
    brands: brandReducer,
    categories: categoryReducer,
    promotions: promotionReducer,
    products: productReducer,
    orders: orderReducer,
    users: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authAPI.middleware,
      brandAPI.middleware,
      branchAPI.middleware,
      categoryAPI.middleware,
      userAPI.middleware,
      dashboardAPI.middleware,
      orderAPI.middleware,
      productAPI.middleware,
      promotionAPI.middleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);
