import useAuth from '../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

// !####### MAIN FUNC ##########
const PrivateRoutes = () => {
  // -> Variables
  const auth = useAuth();

  // -> CHECK AUTH
  if (auth.authenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoutes;
