import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_BRANCHES,
    GET_PRODUCTS,
    GET_PROMOTIONS,
    GET_ORDERS,
    GET_BRANDS,
} from '../endPoints';
import { headers } from '../baseUrl/setHeaders';
import { baseURL } from '../baseUrl/baseURL';

export const dashboardAPI = createApi({
    reducerPath: 'dashboardAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getBranches: builder.query({
            query: () => GET_BRANCHES,
        }),
        getProducts: builder.query({
            query: () => GET_PRODUCTS,
        }),
        getPromotions: builder.query({
            query: () => GET_PROMOTIONS,
        }),
        getOrders: builder.query({
            query: () => GET_ORDERS,
        }),
        getBrands: builder.query({
            query: () => GET_BRANDS,
        }),
    }),
});

export const {
    useGetBranchesQuery,
    useGetProductsQuery,
    useGetPromotionsQuery,
    useGetOrdersQuery,
    useGetBrandsQuery,
} = dashboardAPI;
