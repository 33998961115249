import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customers: [],
    managers: [],
    customer: {},
    manager: {},
    customerOrders: [],
};

export const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomers: (state, action) => {
            state.customers = action.payload;
        },
        setCustomer: (state, action) => {
            state.customer = action.payload;
        },
        setManagers: (state, action) => {
            state.managers = action.payload;
        },
        setManager: (state, action) => {
            state.manager = action.payload.manager;
        },
        setCustomerOrders: (state, action) => {
            state.customerOrders = action.payload;
        },
    },
});

export const {
    setCustomers,
    setCustomer,
    setManagers,
    setManager,
    setCustomerOrders,
} = customerSlice.actions;

export default customerSlice.reducer;
