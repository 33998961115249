import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orders: [],
    order: {},
    orderDelivered: false,
    orderShipped: false,
};

export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setOrderDelivered: (state, action) => {
            state.orderDelivered = action.payload.orderDelivered;
        },
        setOrderShipped: (state, action) => {
            state.orderShipped = action.payload.orderShipped;
        },
    },
});

export const {
    setOrders,
    setOrder,
    setOrderDelivered,
    setOrderShipped,
} = orderSlice.actions;

export default orderSlice.reducer;
